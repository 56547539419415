import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

export default function PropertySearchByAreas({ data }) {
  const { name } = data.allContentfulAreas.edges[0].node
  const properties = data.allContentfulProperties.edges

  return (
    <Layout>
      <section className="px-4">
        <h2 className="py-4 text-xl text-blue-700 border-b pb-2 mb-8">
          {name}エリアの物件情報
        </h2>
        {properties.length === 0 ? (
          <section className="mb-12">
            <h2>該当する物件がありません</h2>
          </section>
        ) : (
          <sction className="mb-12">
            <div class="grid lg:grid-cols-2 gap-4 mb-12">
              {properties.map(({ node }) => (
                <div className="h-64 bg-white shadow-xl rounded">
                  <Link to={`/properties/${node.id}`}>
                    <div class="grid grid-cols-2 gap-0">
                      <img
                        className="object-cover h-64 rounded-l"
                        src={node.images[0]?.file?.url}
                        alt={node.propertyCategory.name}
                      />
                      <div className="p-4">
                        <div className="mb-2">
                          <span className="bg-blue-600 text-white rounded py-1 px-2 mr-2">
                            {node.propertyCategory.name}
                          </span>
                          <span>{node.propertyName}</span>
                        </div>

                        <div>
                          {node.layout} / {node.price}万円
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </sction>
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($areas: String!) {
    allContentfulAreas(filter: { slug: { eq: $areas } }) {
      edges {
        node {
          name
          id
          slug
        }
      }
    }
    allContentfulProperties(filter: { area: { slug: { eq: $areas } } }) {
      edges {
        node {
          id
          propertyCode
          propertyName
          propertyCategory {
            name
          }
          area {
            name
          }
          status
          price
          adress
          nearestStation
          transportation
          layout
          layoutDetail
          structure
          bedroom
          bathroom
          landSpaceSqm
          buildingSpaceSqm
          completion
          landOwnership
          landCategory
          cityPlanningArea
          useZoning
          otherLegalRestriction
          buildingCoverageRatio
          floorAreaRatio
          terrain
          adjacentRoad
          parking
          utilities
          occupancy
          handover
          conditionsTransactions
          images {
            id
            file {
              url
            }
          }
          tags {
            name
          }
          propertyLocation {
            lat
            lon
          }
          comment {
            id
            comment
          }
          onePoint {
            id
            onePoint
          }
          remark {
            id
            remark
          }
          pic
          createdAt
          updatedAt
        }
      }
    }
  }
`
